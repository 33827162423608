/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Information() {
  return (
    
    <MKBox component="section" id="about-us" py={12}>
      <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          marginBottom={10}
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Who We Are</MKTypography>
          
          <MKTypography variant="body1" color="text" mb={2}>
              Lumtice Digital Systems - Software Solutions and Digital Marketing.
          </MKTypography>
        </Grid>
      <Container>
        
        <Grid container spacing={3} alignItems="center">
        
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="people"
                    title="Client-Focused Approach"
                    description="We prioritize our clients' needs above all else, tailoring our strategies and solutions to meet your unique goals. Your success is our success, and we’re dedicated to delivering results that matter to you."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="widgets"
                    title="Expertise Across Digital Solutions"
                    description="From web and mobile app development to digital marketing and brand identity, our diverse expertise ensures that we can provide comprehensive solutions for every aspect of your digital presence."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="lightbulb"
                    title="Innovative & Reliable"
                    description="We bring creativity and reliability to every project. Our team is committed to pushing boundaries while maintaining a professional standard of excellence in each service we offer."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="handshake"
                    title="Long-Term Partnership"
                    description="We’re here for the long haul, offering continuous support and guidance. As your digital partner, we’re invested in your growth and success over the long term."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Your Partner in Digital Transformation"
              description="At Lumtice Digital Systems, we’re more than a service provider—we’re your digital growth partner. With a passion for innovation and a commitment to excellence, we deliver solutions that elevate your brand, connect with your audience, and drive measurable success. Our team combines technical expertise with creative vision, ensuring every project is uniquely tailored to help you stand out in the digital landscape. Let’s work together to make your vision a reality."
              action={{
                type: "internal",
                route: "pages/landing-pages/contact-us",
                color: "info",
                label: "Contact Us",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
