/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";

import googleLogo from "assets/images/logos/gray-logos/logo-google.svg";
import amazonLogo from "assets/images/logos/gray-logos/logo-amazon.svg";
import microsoftLogo from "assets/images/logos/gray-logos/logo-microsoft.svg";
function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Trusted by over</MKTypography>
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            - 50+ CLients
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Many companies, startups, stores love
            Lumtice Digital Systems.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Michael Ekanems"
              date=""
              review="Working with Lumtice Digital Systems was a game-changer for our business. They took the time to understand our needs and delivered a website that exceeded our expectations. The process was smooth, and their team was always responsive and professional. We’ve seen significant growth in our online presence since the launch!"
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="Chelsea Mbaike"
              date=""
              review="Lumtice Digital Systems transformed our online presence! They built us a sleek and modern website that has already attracted new clients. The team was professional, responsive, and delivered exactly what we envisioned. We couldn’t be happier with the results!"
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Bassey Ekanem"
              date=""
              review="I approached Lumtice for a mobile app, and they exceeded my expectations. The app is fast, intuitive, and beautifully designed. The team kept me updated throughout the process and provided excellent post-launch support. I highly recommend their services!"
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={appleLogo} alt="Apple" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={facebookLogo} alt="Facebook" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={googleLogo} alt="Google Ads" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={amazonLogo} alt="Amazon AWS" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={microsoftLogo} alt="Micrsoft Azure" width="100%" opacity={0.6} />
          </Grid>
          
         
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
