/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" id="services" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Empowering Your Business with 


                    <br />
                    Cutting-Edge Digital Solutions
                  </>
                }
                description="From stunning websites and intuitive mobile apps to powerful digital marketing strategies, Lumtice Digital Systems delivers the tools your business needs to thrive in the digital age. Whether you're looking to build an online presence, develop a mobile app, or enhance your brand identity, we’ve got you covered."
              />
              <RotatingCardBack
                image={bgBack}
                title="Discover More"
                description="You will save a lot of time going from prototyping to full-functional code because all elements are implemented."
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "start with header",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="web"
                  title="Web Design & Development"
                  description=" Our expertise includes website development, website management, landing pages, and funnels. Whether you need a fresh design or ongoing maintenance, we provide tailored solutions for all your web needs. We also offer UX/UI design to ensure an intuitive and seamless user experience."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="smartphone"
                  title="Mobile Apps Development"
                  description="We bring your ideas to life through powerful mobile applications.
                  Our team builds Android and iOS apps with seamless performance and user-friendly interfaces. We also offer API integration to connect your app with external services and provide scalable, secure solutions for your mobile projects."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="campaign"
                  title="Digital Marketing & Brand Identity"
                  description="Boost your online presence and build a recognizable brand.
                  From social media management to SEO, Facebook Ads, and Google Ads, we help you reach and engage your target audience. Our branding services ensure a consistent and professional image across all platforms, helping you stand out in a crowded market."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="shopping_cart"
                  title="E-commerce Development"
                  description="Start selling online with our custom e-commerce solutions.
                  We develop e-commerce platforms tailored to your needs, whether you’re selling products or services. Our solutions include payment gateway integration, product management, and an optimized shopping experience for your customers."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
