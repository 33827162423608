/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/1698672968124.jpeg";
import team2 from "assets/images/bruce-mars.jpg";
import team3 from "assets/images/ivana-squares.jpg";
import team4 from "assets/images/ivana-square.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              The Executive Team
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
            Meet the creative minds and technical experts behind Lumtice Digital Systems. Our team is a blend of innovators, strategists, and developers, each dedicated to crafting digital solutions that drive success. With a commitment to collaboration and a focus on excellence, we bring your ideas to life and support your growth every step of the way.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
            <HorizontalTeamCard
                image={team1}
                name="Justice Clement"
                position={{ color: "info", label: "Founder/Software Engineer" }}
                description="Justice Clement is a visionary software engineer and the founder of Lumtice Digital Systems. With a strong background in web and mobile app development, he leads the team in creating innovative digital solutions tailored to client needs."
              />

            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
            <HorizontalTeamCard
                image={team2}
                name="Frank Ameh"
                position={{ color: "info", label: "UI/UX & Graphics Designer" }}
                description="Frank Ameh is a talented UI/UX and graphics designer with a keen eye for detail and aesthetics. He crafts user-friendly interfaces and visually compelling designs, ensuring every project is both functional and engaging."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
            <HorizontalTeamCard
                image={team3}
                name="Ana Vera"
                position={{ color: "info", label: "Project Manager" }}
                description="Ana Vera is an experienced project manager dedicated to seamless project execution and client satisfaction. With exceptional organizational skills and a strategic mindset, she ensures every project is delivered on time and exceeds expectations."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
            <HorizontalTeamCard
              image={team4}
              name="Prince Samuel"
              position={{ color: "info", label: "Digital Marketing Specialist" }}
              description="Price Samuel is a skilled digital marketing specialist with a focus on driving brand growth and online engagement. His expertise in SEO, content marketing, and social media strategies helps clients reach their target audience effectively."
            />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
